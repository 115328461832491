import React, {useState, useEffect} from 'react';
import SkateboardMockup2 from './SkateboardMockup2';
const elementalsData = require('../data/elementals');
const axios = require('axios').default;
const servercall = `${process.env.REACT_APP_SERVER_BASE_URL}`

const Search2 = () => {

    const [collection, setCollection] = useState('')
    console.log(collection)
    const [companion, setCompanion] = useState(false)
    const [selfie, setSelfie] = useState(false)
    const [beanzId, setBeanzId] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [companionImageUrl, setCompanionImageUrl] = useState('');
    const [idTarget, setIdTarget] = useState('');
    const [error, setError] = useState(false);
    const [isLoadingMainImage, setIsLoadingMainImage] = useState(false);
    const [isLoadingCompanionImage, setIsLoadingCompanionImage] = useState(false);
    const [prevCompanion, setPrevCompanion] = useState(false);
    const [prevBeanzId, setPrevBeanzId] = useState(false);

    let azukiLink = 'https://azk.imgix.net/big_azukis/a-';
    let azukiCompanionLink = 'https://azukiimagemaker.vercel.app/api/pairbeanz-prod?azukiId=';
    let azukiCompanionLinkExt = '&beanzId=';

    let elementalsLink = 'https://elementals-images.azuki.com/';
    let newElementalsLink = `https://elementals-metadata.azuki.com/elemental/`
    let elementalsCompanionLink = 'https://azukiimagemaker.vercel.app/api/pairbeanz-elemental?elementalId=';
    let elementalsCompanionLinkExt = '&beanzId=';

    let beanzLink = 'https://azkimg.imgix.net/images/final-';
    let selfieLink = 'https://azkimg.imgix.net/images_squareface/final-'

    const handleAzuki = () =>{
        clear();
        setCollection('azuki')
    };

    const handleElementals = () => {
        clear();
        setCollection('elementals')
    };

    const handleBeanz = () => {
        clear();
        setCollection('beanz')
    }

    const handleIdChange = (event) => {
        setIdTarget(event.target.value);
        setIsLoadingMainImage(true);
    };

    const clear = () => {
        setCollection('');
        setCompanion(false);
        setSelfie(false);
        setBeanzId('');
        setIdTarget('');
        setImageUrl('');
        setError(false)
    }


    const handleCompanion = (event) => {
        const newCompanion = event.target.checked;
        setCompanion(newCompanion);
        if (newCompanion) {
          setIsLoadingCompanionImage(true); // Start loading if companion is selected
        } else {
          // If the companion flag is deselected, reset the companion image URL
          setCompanionImageUrl('');
          // Reset loader state
          setIsLoadingCompanionImage(false);
          // Reset image URL
          setImageUrl('');
           // Reset beanzID
            setBeanzId('');
          console.log('COMPANION IN SEARCH', companion)
          console.log('BEAAAANZZZZ', beanzId)
        }
      };
      
     
       

    const handleBeanzId = (event) => {
        setBeanzId(event.target.value);
    };

    const handleSelfie = (event) => {
        setSelfie(event.target.checked);
        console.log(selfie)
    };

    //fetc with proxy
    const getElementalsImageUrl = async (elementalId) => {
        try {
            //const response = await fetch(`${newElementalsLink}${elementalId}`);
            const response = await fetch(`${servercall}get-elementals-image?elementalId=${elementalId}`);
            const data = await response.json();
            console.log('DATA', data)
            let imageUrl = data.imageUrl;
            console.log('IMMAGINE', imageUrl)
            imageUrl = imageUrl.replace('.png', '-bigazuki.png');
            return imageUrl;
        } catch (error) {
            console.log('ERROREEE', error)
            console.error('Error fetching Elemental image:', error);
            return '';
        }
    };

    //old fetch without proxy
    /*
        const getElementalsImageUrl = async (elementalId) => {
        try {
            const response = await fetch(`${newElementalsLink}${elementalId}`);
            const data = await response.json();
            let imageUrl = data.image;
            imageUrl = imageUrl.replace('.png', '-bigazuki.png');
            return imageUrl;
        } catch (error) {
            console.error('Error fetching Elemental image:', error);
            return '';
        }
    };
    */

    
    useEffect(() => {
        let url = '';
        let companionUrl = '';
        setPrevCompanion(companion);
        setPrevBeanzId(beanzId);

        if (companion !== prevCompanion || beanzId !== prevBeanzId) {
            handleCompanion({ target: { checked: companion } });
            handleBeanzId({ target: { value: beanzId } });
        }

        //still missing controls if azuki/azukiCompanion/beanz id doesn't exist/not found!!
        switch (collection) {
            case 'azuki':
                url = `${azukiLink}${idTarget}.png`;
                companionUrl = companion && beanzId ? `${azukiCompanionLink}${idTarget}${azukiCompanionLinkExt}${beanzId}` : '';
                break;
            case 'elementals':
                /*if(idTarget){
                    setError(false)
                    const elementalObject = elementalsData.find((element) => element[idTarget]);
                    if (elementalObject) {
                        console.log(elementalObject)
                        url = `${elementalsLink}${elementalObject[idTarget]}-bigazuki.png`;
                            if (companion && beanzId) {
                                companionUrl = `${elementalsCompanionLink}${idTarget}${elementalsCompanionLinkExt}${beanzId}`;
                            }
                    } else {
                        console.log('NO ELEMENTAL OBJECT')
                        setError(true)
                    }
                }*/
                if (idTarget) {
                    getElementalsImageUrl(idTarget).then(fetchedUrl => {
                        if (fetchedUrl) {
                            console.log('LINK FETCHED',fetchedUrl)
                            setError(false)
                            setImageUrl(`${servercall}?imageUrl=${encodeURIComponent(fetchedUrl)}`);
                            if (companion && beanzId) {
                                companionUrl = `${elementalsCompanionLink}${idTarget}${elementalsCompanionLinkExt}${beanzId}`;
                                setCompanionImageUrl(`${servercall}?imageUrl=${encodeURIComponent(companionUrl)}`);
                            }
                        } else {
                            setError(true);
                            // Reset images 
                            setImageUrl('');
                            setCompanionImageUrl('');
                        }
                    }).catch(() => {
                        setError(true);
                        // Reset images 
                        setImageUrl('');
                        setCompanionImageUrl('');
                    });
                }
                /*const fetchImageData = async () => {
                    if (!idTarget) return;
            
                    try {
                        console.log('avviata fetch')
                        const fetchedUrl = await getElementalsImageUrl(idTarget);
                        if (fetchedUrl) {
                            console.log('LINK FETCHED', fetchedUrl);  
                            setError(false);
                            setImageUrl(`${servercall}?imageUrl=${encodeURIComponent(fetchedUrl)}`);
                            if (companion && beanzId) {
                                const companionUrl = `${elementalsCompanionLink}${idTarget}${elementalsCompanionLinkExt}${beanzId}`;
                                setCompanionImageUrl(`${servercall}?imageUrl=${encodeURIComponent(companionUrl)}`);
                            }
                        } else {
                            console.log('NON HA TROVATO IMMAGINE');
                            throw new Error('No image found');
                        }
                    } catch (error) {
                        console.error('Error fetching image:', error);
                        setError(true);
                        // Reset images 
                        setImageUrl('');
                        setCompanionImageUrl('');
                    }
                };
                fetchImageData();
                console.log('chiamata fetch')*/
                break;
            case 'beanz':
                url = selfie ? `${selfieLink}${idTarget}.png` : `${beanzLink}${idTarget}.png`;
                break;
            default:
                break;
        }

        /*if (collection === 'elementals' && !url) {
            url = 'indirizzo_immagine_di_attesa.png'; // Fake Url in order to show skateboard for elementals
          }*/

        // Update imageURL and companionImageUrl based on the URLs created
        /*if(!error){
            setImageUrl(url ? `${servercall}?imageUrl=${encodeURIComponent(url)}` : '');
            setCompanionImageUrl(companionUrl ? `${servercall}?imageUrl=${encodeURIComponent(companionUrl)}` : '');
        }*/

        /*if (!error) {
            if (collection !== 'elementals') {
                setImageUrl(url ? `${servercall}?imageUrl=${encodeURIComponent(url)}` : '');
                setCompanionImageUrl(companionUrl ? `${servercall}?imageUrl=${encodeURIComponent(companionUrl)}` : '');
            }
        }*/

        if (!error && collection !== 'elementals') {
            setImageUrl(url ? `${servercall}?imageUrl=${encodeURIComponent(url)}` : '');
            setCompanionImageUrl(companionUrl ? `${servercall}?imageUrl=${encodeURIComponent(companionUrl)}` : '');
        }

    }, [collection, idTarget, beanzId, companion, selfie, error, prevCompanion, prevBeanzId]);
    



  return (
    <>
        <div className='flex flex-col items-center lg:flex lg:flex-row lg:justify-center lg:items-center gap-5 overflow-y-hidden'>
            <div className='flex flex-col justify-center items-center w-[500px]'>
                <div className='flex flex-row gap-1 justify-center g-white rounded-lg font-bold'>
                    <button   className={`${collection === 'azuki' ? 'bg-orange-300 p-2 rounded-lg' : null}`} onClick={handleAzuki}> AZUKI</button>
                    <button className={`${collection === 'elementals' ? 'bg-orange-300 p-2 rounded-lg' : null}`} onClick={handleElementals}> ELEMENTALS</button>
                    <button className={`${collection === 'beanz' ? 'bg-orange-300 p-2 rounded-lg' : null}`} onClick={handleBeanz}> BEANZ</button>
                </div>
                <div className='flex flex-row justify-center gap-2 mt-2 '>
                    <input className='w-60 font-primary border-2 border-orange-300 bg-orange-300 rounded-lg p-2' placeholder='id' type='text' onChange={handleIdChange} value={idTarget}></input>
                </div>
                    {collection === 'azuki' && idTarget && imageUrl &&
                        (
                        <>
                            <div className='flex justify-center items-center gap-2 g-white p-5 rounded-lg '>
                                <input className='form-checkbox h-4 w-4 text-gray-600 text-xs bg-transparent' placeholder='companion' type='checkbox' onChange={handleCompanion}></input>
                                <span>COMPANION</span>
                            </div>
                        </>
                        )
                    }
                    {error && 
                                <div className='text-red-600 bold italic text-sm'>Sorry, NFT not found</div>
                            }
                    {collection === 'elementals' && idTarget && imageUrl &&
                        (
                        <>
                            <div className='flex justify-center items-center gap-2 g-white p-5 rounded-lg'>
                                <input className="form-checkbox h-4 w-4 text-gray-600 text-xs bg-transparent" placeholder='companion' type='checkbox' onChange={handleCompanion}></input>
                                <span>COMPANION</span>
                            </div>
                        </>
                        )
                    }
                    {collection === 'beanz' && idTarget && imageUrl &&
                        (
                        <>
                            <div className='flex justify-center gap-2 g-white items-center p-5 rounded-lg'>
                                <input className='form-checkbox h-4 w-4 text-gray-600 text-xs bg-transparent' placeholder='selfie' checked={selfie} type='checkbox' onChange={handleSelfie} ></input>
                                <span>SELFIE</span>
                            </div>
                        </>
                        )
                    }
                    {companion &&
                    (
                    <>
                        <div className='flex flex-row justify-center gap-2 mt-2'>
                            <input className='w-60 rounded-lg bg-orange-300 p-2 text-black' placeholder='BeanzId' type='text' onChange={handleBeanzId}></input>
                        </div>
                    </>
                    )
                    }
            </div>
            {  imageUrl && (
            <div className="flex justify-center items-center p-5 w-[500px] h-[500px]">
                <SkateboardMockup2 imageUrl={imageUrl} companionImageUrl={companionImageUrl} collection={collection} selfie={selfie} key={imageUrl} companion={companion} isLoadingCompanionImage={isLoadingCompanionImage} isLoadingMainImage={isLoadingMainImage} setIsLoadingCompanionImage={setIsLoadingCompanionImage} setIsLoadingMainImage={setIsLoadingMainImage}/>                
            </div>
            )
            }
        </div>
    </>
  )
}

export default Search2