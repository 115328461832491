import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import image1 from "../asset/1.jpg";
import image2 from "../asset/2.jpg";
import image3 from "../asset/3.jpg";
import imageMob1 from "../asset/1_mob.jpg";
import imageMob2 from "../asset/2_mob.jpg";
import imageMob3 from "../asset/3_mob.jpg";
import koboLogo from "../asset/logo_brown_V1.svg";
import wheel from "../asset/weel_icon.png";
import wheel2 from "../asset/weel_icon_2.png";

import skateSound1 from "../asset/skate/sound/1.mp3";
import skateSound2 from "../asset/skate/sound/2.mp3";
import skateSound3 from "../asset/skate/sound/3.mp3";

const Landing = ({ isMobileView }) => {
  const [bgImage, setBgImage] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [wheelDisabled, setWheelDisabled] = useState(false);

  const navigate = useNavigate();

  const imagesDesktop = [image1, image2, image3];
  const imagesMobile = [imageMob1, imageMob2, imageMob3];
  const skateSounds = [skateSound1, skateSound2, skateSound3];

  // Function to play sound
  const playSkateSound = () => {
    const randomIndex = Math.floor(Math.random() * skateSounds.length);
    const audio = new Audio(skateSounds[randomIndex]);
    audio.play();
  };

  function delayAndGo(e, to) {
    if (wheelDisabled) return;
    setWheelDisabled(true);

    e.preventDefault();
    playSkateSound();

    setTimeout(() => navigate(to), 300);
  }

  useEffect(() => {
    const images = window.innerWidth >= 768 ? imagesDesktop : imagesMobile;
    const randomIndex = Math.floor(Math.random() * images.length);
    const image = new Image();
    image.src = images[randomIndex];
    image.onload = () => {
      setBgImage(images[randomIndex]);
      setImageLoaded(true);
    };
  }, []);

  return (
    <>
      <div
        className={`h-screen bg-cover bg-center overflow-y-hidden object-fill flex flex-col items-center pt-5 transition-opacity duration-500 ${
          imageLoaded ? "opacity-100" : "opacity-0"
        } md:bg-cover`}
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div
          className={`transition-opacity duration-500 ${
            imageLoaded ? "opacity-100" : "opacity-0"
          }`}
        >
          <img src={koboLogo} className="w-40 h-50" />
        </div>
        <div
          onClick={playSkateSound}
          className={`absolute ${
            isMobileView ? "bottom-4 right-4" : "bottom-10 right-10"
          } transition-opacity ${
            imageLoaded && !wheelDisabled ? "opacity-100" : "opacity-0"
          } ${
            wheelDisabled ? "delay-0 duration-100" : "delay-1000 duration-500"
          }`}
        >
          <Link onClick={(e) => delayAndGo(e, "/workshop")}>
            <img src={wheel} className="absolute bottom-0 w-24 h-24 rotate" />
            <img src={wheel2} className=" w-24 h-24" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Landing;
