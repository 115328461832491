import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SkateboardMockup3 from "./SkateboardMockup3";
import SkateboardMockup4 from "./SkateboardMockup4";
import { FiSearch } from "react-icons/fi";
import testSticker from "../asset/testSticker.png";
import koboLogo from "../asset/logo_brown_V1.svg";
import footerImage from "../asset/BeanzFooter.png";
import { MdFileUpload } from "react-icons/md";
import shareIcon from "../asset/share.svg";
import flagIcon from "../asset/selection_1.svg";
import flagIconHover from "../asset/selection_2_over.svg";
import flagIconSelected from "../asset/selection_3.svg";
import { act } from "react-dom/test-utils";
import * as htmlToImage from "html-to-image";
import domtoimage from "dom-to-image";
import * as Konva from "konva";
import testAzuki from "../asset/6815b1cd-8702-49ff-a5d3-e4a91af6ff58-bigazuki.png";
import testAzukiCompanion from "../asset/pairbeanz-elemental.png";
import ProductDisclaimer from "./ProductDisclaimer";
import Footer from "./Footer";
import Footer2 from "./Footer2";
import Footer3 from "./Footer3";
import Footer4 from "./Footer4";
import { localData } from "../data/localDataUtils";
import { Order } from "./Order";
import { Modal } from "./Modal";
import useImage from "use-image";

const elementalsData = require("../data/elementals");
const axios = require("axios").default;

// Am I on an iOS device?
const isOnIOS =
  navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
const eventName = isOnIOS ? "pagehide" : "beforeunload";

const Search3 = ({
  collection,
  setCollection,
  orderOpen,
  setOrderOpen,
  idTarget,
  companion,
  selfie,
  beanzId,
  imageUrl,
  companionImageUrl,
  isLoadingMainImage,
  setIsLoadingMainImage,
  isLoadingCompanionImage,
  setIsLoadingCompanionImage,
  activeStickers,
  setActiveStickers,
  handleStickerClick,
  toggleFooter,
  isMobileView,
}) => {
  const skateboardRef = useRef(null);

  const Konva = require("konva");
  const [zoomOpen, setZoomOpen] = useState(false);
  const [zoomImageB64, setZoomImageB64] = useState("");
  const [workshopData, setWorkshopData] = useState({});

  const containerRef = useRef(null);

  const toggleOrder = async () => {
    disableStickersControls();
    await waitUntil(50);
    const image = getBase64("image/jpeg");
    setWorkshopData({
      collection,
      idNft: idTarget,
      companion,
      companionId: beanzId,
      selfie,
      image,
    });
    setOrderOpen(!orderOpen);
  };

  function toggleZoomModal() {
    if (!zoomOpen) {
      disableStickersControls();
      setZoomOpen(true);
    } else {
      setZoomOpen(false);
    }
  }

  useEffect(() => {
    const openDelay = async () => {
      if (zoomOpen) {
        await waitUntil(50);
        const imageBase64 = getBase64();
        setZoomImageB64(imageBase64);
      }
    };
    openDelay();
  }, [activeStickers]);

  const waitUntil = (milliseconds) =>
    new Promise((resolve) => setTimeout(resolve, milliseconds));

  const handleRefreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      setActiveStickers([]);
      clearCollectionData();
    };

    window.addEventListener(eventName, handleBeforeUnload);

    return () => {
      window.removeEventListener(eventName, handleBeforeUnload);
    };
  }, []);

  //non riesco ad esportare immagine
  const downloadImage = (uri) => {
    const link = document.createElement("a");
    link.download = "my-skate-design.png";
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openTwitter = () => {
    const text = "My new KŌBŌ design, visit kobocraft.xyz";
    const tweetUrl = `https://x.com/intent/tweet?text=${text}`; //&url=${imageUrl}`;
    window.open(tweetUrl, "_blank");
  };

  function getBase64AndDownloadImage() {
    const data64 = getBase64();
    downloadImage(data64);
    return data64;
  }

  //non riesco ad esportare immagine
  const handleDownloadAndTweet = () => {
    // downloadImage(data64);
    const data64 = getBase64AndDownloadImage();
    // Logica per aprire un tweet su Twitter
    openTwitter(data64);
    // if (skateboardRef.current) {
    //   console.log("Ref to Konva Stage:", skateboardRef.current);
    //   const uri = skateboardRef.current.toDataURL({
    //     mimeType: "image/png",
    //     quality: 5.0,
    //   });
    //   console.log("URI", uri);
    //   // Logica per il download
    // }
  };

  function getBase64() {
    const stage = Konva.stages[0];
    const dataURL = stage.toDataURL({ pixelRatio: 5 });

    return dataURL;
  }

  //non riesco ad esportare immagine
  // function handleSaveButtonClick() {
  //   const stage = Konva.stages[0]; // sostituisci con l'ID del tuo stage

  //   if (stage) {
  //     stage.toDataURL({
  //       callback: function (dataUrl) {
  //         fetch(`${servercall}save-image`, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             imageData: dataUrl.split(",")[1],
  //             imageName: "mockup.png",
  //           }),
  //         })
  //           .then((res) => {
  //             if (!res.ok) {
  //               throw new Error("Network response was not ok");
  //             }
  //             return res.blob();
  //           })
  //           .then((blob) => {
  //             const url = window.URL.createObjectURL(blob);
  //             const link = document.createElement("a");
  //             link.href = url;
  //             link.setAttribute("download", "image.png");
  //             document.body.appendChild(link);
  //             link.click();
  //             document.body.removeChild(link);
  //           })
  //           .catch((error) => {
  //             console.error("Error:", error);
  //           });
  //       },
  //     });
  //   } else {
  //     console.log("no stage");
  //   }
  // }

  const clearCollectionData = () => {
    localData.clear();
  };

  function disableStickersControls() {
    setActiveStickers((prevStickers) => {
      return prevStickers.map((sticker) => {
        return {
          ...sticker,
          controlsActive: false,
        };
      });
    });
  }

  return (
    <>
      {/* {showMobilePopup && (
        <>
          <div className="flex flex-col justify-center items-center md:h-screen relative font-primary">
            <img src={koboLogo} className="w-40 h-50" />
            <div>
              <h1 className="text-3xl font-primary">Go to Desktop</h1>
            </div>
          </div>
        </>
      )} */}
      <div
        className={`flex flex-col h-max relative font-primary overflow-hidden bg-zinc-100 grow z-10`}
      >
        <div
          className={`flex flex-row justify-between items-center pb-1 pt-2 2xl:pt-5 px-5 bg-zinc-100 w-full z-[20]${
            isMobileView ? "pt-5" : ""
          }`}
        >
          <div>
            <img
              alt="logo"
              onClick={handleRefreshPage}
              src={koboLogo}
              className="w-24 md:w-40 h-50 cursor-pointer"
            />
          </div>
          <div className="flex flex-col items-center">
            <div className="flex flex-row items-center justify-end gap-3">
              {/* <button className="py-1" onClick={handleDownloadAndTweet}>
                <img
                  src={shareIcon}
                  className="rounded-3xl w-8 h-8 bg-white border border-gray-200 text-black cursor-pointer object-fit"
                />
              </button> */}
              {!isMobileView && (
                <>
                  <button
                    className="rounded-3xl w-40 bg-white border border-gray-200 text-black py-1 px-3"
                    onClick={toggleFooter}
                  >
                    Terms of Service
                  </button>
                  <button
                    className={`rounded-3xl w-28 border text-white py-1 px-2 z-[99] ${
                      !idTarget
                        ? "bg-kobo-o3 border-kobo-o4"
                        : "bg-kobo-o1 border-kobo-o2"
                    }`}
                    onClick={toggleOrder}
                    disabled={!idTarget}
                  >
                    {orderOpen ? "Close Form" : "Order Now"}
                  </button>
                </>
              )}
              {isMobileView && (
                <button
                  className="rounded-3xl w-30 bg-white border border-gray-200 text-black py-1 px-3 font-semibold text-kobo-15"
                  onClick={getBase64AndDownloadImage}
                >
                  Download
                </button>
              )}
            </div>
          </div>
          {orderOpen && <Order data={workshopData}></Order>}
          {zoomOpen && zoomImageB64 && (
            <Modal
              isVertical={collection === "beanz"}
              closeFn={() => {
                setZoomOpen(false);
                setZoomImageB64("");
              }}
            >
              <img
                src={zoomImageB64}
                alt="Skate mockup"
                className={`${
                  collection !== "beanz"
                    ? "-translate-y-[15%] 2xl:-translate-y-[25%]"
                    : ""
                }`}
              ></img>
            </Modal>
          )}
        </div>
        <div
          ref={containerRef}
          id="canvas-div"
          className={`relative flex flex-row justify-center grow ${
            isMobileView ? "h-1/2" : "h-2/3"
          }`}
        >
          <SkateboardMockup3
            id="mockup"
            imageUrl={imageUrl}
            companionImageUrl={companionImageUrl}
            collection={collection}
            selfie={selfie}
            key={imageUrl}
            companion={companion}
            isLoadingCompanionImage={isLoadingCompanionImage}
            isLoadingMainImage={isLoadingMainImage}
            setIsLoadingCompanionImage={setIsLoadingCompanionImage}
            setIsLoadingMainImage={setIsLoadingMainImage}
            idTarget={idTarget}
            activeStickers={activeStickers}
            setActiveStickers={setActiveStickers}
            beanzId={beanzId}
            deleteEvent={handleStickerClick}
            openModalZoom={toggleZoomModal}
            isMobileView={isMobileView}
          />
        </div>
      </div>
    </>
  );
};

export default Search3;
