import { useEffect, useState } from "react";
import sticker1 from "../asset/stickers_final-01.png";
import sticker2 from "../asset/stickers_final-02.png";
import sticker3 from "../asset/stickers_final-03.png";
import sticker4 from "../asset/stickers_final-04.png";
import sticker5 from "../asset/stickers_final-05.png";
import sticker6 from "../asset/stickers_final-06.png";
import sticker7 from "../asset/stickers_final-07.png";
import sticker8 from "../asset/stickers_final-08.png";
import sticker9 from "../asset/stickers_final-09.png";
import sticker10 from "../asset/stickers_final-10.png";
import sticker11 from "../asset/stickers_final-11.png";
import sticker12 from "../asset/stickers_final-12.png";
import sticker13 from "../asset/stickers_final-13.png";
import sticker14 from "../asset/stickers_final-14.png";
import sticker15 from "../asset/stickers_final-15.png";
import sticker16 from "../asset/stickers_final-16.png";
import sticker17 from "../asset/stickers_final-17.png";
import sticker18 from "../asset/stickers_final-18.png";
import sticker19 from "../asset/stickers_final-19.png";
import sticker20 from "../asset/stickers_final-20.png";
import sticker21 from "../asset/stickers_final-21.png";
import sticker22 from "../asset/stickers_final-22.png";
import sticker23 from "../asset/stickers_final-23.png";
import sticker24 from "../asset/stickers_final-24.png";
import sticker25 from "../asset/stickers_final-25.png";
import sticker26 from "../asset/stickers_final-26.png";
import sticker27 from "../asset/stickers_final-27.png";
import sticker28 from "../asset/stickers_final-28.png";
import sticker29 from "../asset/stickers_final-29.png";
import sticker30 from "../asset/stickers_final-30.png";
import sticker31 from "../asset/stickers_final-31.png";
import sticker32 from "../asset/stickers_final-32.png";
import sticker33 from "../asset/stickers_final-33.png";
import sticker34 from "../asset/stickers_final-34.png";

import unrevealed from "../asset/unrevealed_elementals.jpg";
import iconSearch from "../asset/search_icon-01.svg";
import iIcon from "../asset/i.svg";
import clearIcon from "../asset/clear.svg";
import bobu from "../asset/40_azuki_bobu.jpg";
import { localData } from "../data/localDataUtils";

const stickers = [
  sticker1,
  sticker2,
  sticker3,
  sticker4,
  sticker5,
  sticker6,
  sticker7,
  sticker8,
  sticker9,
  sticker10,
  sticker11,
  sticker12,
  sticker13,
  sticker14,
  sticker15,
  sticker16,
  sticker17,
  sticker18,
  sticker19,
  sticker20,
  // sticker21,
  sticker34,
  sticker22,
  sticker23,
  sticker24,
  sticker25,
  sticker26,
  sticker27,
  sticker28,
  sticker29,
  sticker30,
  sticker31,
  sticker32,
  sticker33,
];
const stickersName = [
  { src: sticker1, name: "Kōbō Logo" },
  { src: sticker2, name: "Kōbō Mahjong" },
  { src: sticker3, name: "Golden Skateboard" },
  { src: sticker4, name: "White Bean" },
  { src: sticker5, name: "Ikuzo" },
  { src: sticker6, name: "Torii Gate" },
  { src: sticker7, name: "Garden Caretakers" },
  { src: sticker8, name: "BBB" },
  { src: sticker9, name: "The Espresso Shot" },
  { src: sticker10, name: "Azuki Korea" },
  { src: sticker11, name: "Azuki NYC" },
  { src: sticker12, name: "Spanishzuki" },
  { src: sticker13, name: "Alpha Beans" },
  { src: sticker14, name: "852 Garden" },
  { src: sticker15, name: "Kinder Garden" },
  { src: sticker16, name: "Kigu" },
  { src: sticker17, name: "Roy From OP" },
  { src: sticker18, name: "Bobu’s Cards Shop" },
  { src: sticker19, name: "Ikuzo Coffee Supply" },
  { src: sticker20, name: "Ocean Park" },
  { src: sticker21, name: "Indelible" },
  { src: sticker22, name: "Muzuki" },
  { src: sticker23, name: "Bobu’s Kids Books" },
  { src: sticker24, name: "Azuki Taiwan" },
  { src: sticker25, name: "Azooki" },
  { src: sticker26, name: "The Bean Gazette" },
  { src: sticker27, name: "Devzuki" },
  { src: sticker28, name: "Azuki Australia" },
  { src: sticker29, name: "Azuki UK" },
  { src: sticker30, name: "Bean List" },
  { src: sticker31, name: "Skater Of The Internet" },
  { src: sticker32, name: "Azuki Singapore" },
  { src: sticker33, name: "Framebeans" },
  { src: sticker34, name: "Azuki Europe" },
];

const servercall = `${process.env.REACT_APP_SERVER_BASE_URL}`;

const FunctionalTabs = (props) => {
  const {
    collection,
    setCollection,
    orderOpen,
    setOrderOpen,
    idTarget,
    setIdTarget,
    companion,
    setCompanion,
    selfie,
    setSelfie,
    beanzId,
    setBeanzId,
    imageUrl,
    setImageUrl,
    activeStickers,
    setActiveStickers,
    setCompanionImageUrl,
    setIsLoadingMainImage,
    setIsLoadingCompanionImage,
    handleStickerClick,
    isMobileView,
  } = props;

  const [tab, setTab] = useState("azuki");
  const [isHovering, setIsHovering] = useState("");
  const [hoveredStickerName, setHoveredStickerName] = useState("");
  const [error, setError] = useState(false);

  let azukiLink = "https://azk.imgix.net/big_azukis/a-";
  let azukiCompanionLink =
    "https://azukiimagemaker.vercel.app/api/pairbeanz-prod?azukiId=";
  let azukiCompanionLinkExt = "&beanzId=";
  let elementalsCompanionLink =
    "https://azukiimagemaker.vercel.app/api/pairbeanz-elemental?elementalId=";
  let elementalsCompanionLinkExt = "&beanzId=";
  let beanzLink = "https://azkimg.imgix.net/images/final-";
  let selfieLink = "https://azkimg.imgix.net/images_squareface/final-";

  const handleAzuki = () => {
    handleTabOpened("azuki");
    if (collection === "azuki") return;

    clear();
    setCollection("azuki");
    setActiveStickers([]);
  };

  const handleElementals = () => {
    handleTabOpened("elementals");
    if (collection === "elementals") return;
    clear();
    setCollection("elementals");
    setActiveStickers([]);
  };

  const handleBeanz = () => {
    handleTabOpened("beanz");
    if (collection === "beanz") return;
    clear();
    setCollection("beanz");
    setActiveStickers([]);
  };

  const handleTabOpened = (tabSelected) => {
    if (tab === tabSelected) return;
    setTab(tabSelected);
  };

  const handleMouseEnter = (event) => {
    setIsHovering(event.target.dataset.hover);
  };

  const handleMouseLeave = () => {
    setIsHovering("");
  };

  // Funzione per trovare il nome dello sticker basato sul suo src
  const getStickerNameBySrc = (stickerSrc) => {
    const stickerObj = stickersName.find(
      (sticker) => sticker.src === stickerSrc
    );
    return stickerObj ? stickerObj.name : "";
  };

  // Funzione onMouseEnter modificata per usare getStickerNameBySrc
  const handleMouseEnterSticker = (stickerSrc) => {
    const stickerName = getStickerNameBySrc(stickerSrc);
    setHoveredStickerName(stickerName);
    console.log("STICKER NAME", stickerName);
  };

  // Assicurati che la funzione onMouseLeave sia impostata correttamente
  const handleMouseLeaveSticker = () => {
    setHoveredStickerName("");
  };

  const clearStickerData = () => {
    // Ottenere tutte le chiavi della localStorage
    const collectionStorage = localData.getCollection(collection);

    if (collectionStorage) {
      const keys = Object.keys(collectionStorage);

      // Iterare attraverso le chiavi e rimuovere quelle che contengono con 'static media'
      keys.forEach((media) => {
        if (media.includes("/static/media")) {
          handleStickerClick(media);
          localData.removeMedia(collection, media);
        }
      });
    }

    setIsHovering("");
  };

  const clear = () => {
    setCollection("");
    setCompanion(false);
    setSelfie(false);
    setBeanzId("");
    setIdTarget("");
    setImageUrl("");
    setError(false);
  };

  const handleCompanion = (event) => {
    const newCompanion = event.target.checked;
    setCompanion(newCompanion);
    if (newCompanion) {
      setIsLoadingCompanionImage(true); // Start loading if companion is selected
    } else {
      // If the companion flag is deselected, reset the companion image URL
      setCompanionImageUrl("");
      // Reset loader state
      setIsLoadingCompanionImage(false);
      // Reset image URL NOTA: tolta per evitare refresh dell'immagine
      //setImageUrl('');
      // Reset beanzID
      setBeanzId("");
    }
  };

  const handleBeanzId = (event) => {
    setBeanzId(event.target.value);
    localData.setCompanion(collection, event.target.value);
  };

  const handleSelfie = (event) => {
    setSelfie(event.target.checked);
    localData.setSelfie(collection, event.target.checked);
  };

  const handleIdChange = (event) => {
    setIdTarget(event.target.value);
    localData.setId(collection, event.target.value);
    if (event.target.value === "") {
      setCompanion(false);
    }
    setIsLoadingMainImage(true);
  };

  //fetch with proxy
  const getElementalsImageUrl = async (elementalId) => {
    try {
      const response = await fetch(
        `${servercall}get-elementals-image?elementalId=${elementalId}`
      );
      const data = await response.json();
      console.log("DATA", data);
      let imageUrl = data.imageUrl;
      console.log("IMMAGINE", imageUrl);
      imageUrl = imageUrl.replace(".png", "-bigazuki.png");
      return imageUrl;
    } catch (error) {
      console.log("ERROREEE", error);
      console.error("Error fetching Elemental image:", error);
      return "";
    }
  };

  function thanksIOS() {
    window.scrollTo(0, 0);
    window.scroll(0, 0);
    document.body.scrollTop = 0;
  }

  useEffect(() => {
    handleCompanion({ target: { checked: companion } });
  }, [companion]);

  useEffect(() => {
    handleBeanzId({ target: { value: beanzId } });
  }, [beanzId]);

  useEffect(() => {
    let url = "";
    let companionUrl = "";

    const debounceTimeout = setTimeout(() => {
      switch (collection) {
        case "azuki":
          if (idTarget === "40") {
            // Se il targetId è 40, utilizza l'immagine importata
            url = bobu;
          } else {
            url = `${azukiLink}${idTarget}.png`;
            companionUrl =
              companion && beanzId
                ? `${azukiCompanionLink}${idTarget}${azukiCompanionLinkExt}${beanzId}`
                : "";
          }
          if (beanzId === "") {
            // Resetta companionImageUrl e companionImageStatus
            setCompanionImageUrl("");
          }
          break;
        case "elementals":
          if (idTarget) {
            getElementalsImageUrl(idTarget)
              .then((fetchedUrl) => {
                if (fetchedUrl) {
                  console.log("LINK FETCHED", fetchedUrl);
                  setError(false);
                  setImageUrl(
                    `${servercall}?imageUrl=${encodeURIComponent(fetchedUrl)}`
                  );
                  if (companion && beanzId) {
                    companionUrl = `${elementalsCompanionLink}${idTarget}${elementalsCompanionLinkExt}${beanzId}`;
                    setCompanionImageUrl(
                      `${servercall}?imageUrl=${encodeURIComponent(
                        companionUrl
                      )}`
                    );
                  }
                } else {
                  setError(true);
                  // Reset images
                  //setImageUrl('');
                  setImageUrl(unrevealed);
                  console.log("UNREVEALED", unrevealed);
                  setCompanionImageUrl("");
                }
              })
              .catch(() => {
                setError(true);
                // Reset images
                //setImageUrl('');
                setImageUrl(unrevealed);
                setCompanionImageUrl("");
              });
          } else {
            setImageUrl("");
          }
          if (beanzId === "") {
            // Resetta companionImageUrl e companionImageStatus
            setCompanionImageUrl("");
          }
          break;
        case "beanz":
          url = selfie
            ? `${selfieLink}${idTarget}.png`
            : `${beanzLink}${idTarget}.png`;
          break;
        default:
          break;
      }
      if (!error && collection !== "elementals") {
        //setImageUrl(url ? `${servercall}?imageUrl=${encodeURIComponent(url)}` : '');
        //setCompanionImageUrl(companionUrl ? `${servercall}?imageUrl=${encodeURIComponent(companionUrl)}` : '');
        if (collection === "azuki" && idTarget === "40") {
          // Se la collezione è 'azuki' e il targetId è '40', non esegui la chiamata al server
          setImageUrl(url);
        } else {
          setImageUrl(
            url ? `${servercall}?imageUrl=${encodeURIComponent(url)}` : ""
          );
        }
        setCompanionImageUrl(
          companionUrl
            ? `${servercall}?imageUrl=${encodeURIComponent(companionUrl)}`
            : ""
        );
      }
    }, 1000);

    return () => {
      // Questo verrà eseguito per pulire il timeout se il componente viene smontato
      // o se le dipendenze cambiano prima che il timeout scatti.
      clearTimeout(debounceTimeout);
    };
  }, [collection, idTarget, beanzId, selfie, error]);
  // Carica dal localstorage la collezione
  useEffect(() => {
    const collectionObj = localData.getCollection(collection);

    if (collectionObj) {
      const keys = Object.keys(collectionObj);
      // Iterare attraverso le chiavi e rimuovere quelle che contengono con 'static media'
      keys.forEach((media) => {
        if (media.includes("/static/media")) {
          setActiveStickers((prevStickers) => {
            // Altrimenti, aggiungi lo sticker all'array
            const prevList = prevStickers.map((sticker) => {
              return {
                ...sticker,
                controlsActive: false,
              };
            });
            return [
              ...prevList,
              { src: media, x: 0, y: 0, controlsActive: true },
            ];
          });
        } else if (media.includes("Image")) {
          setImageUrl(collectionObj[media]);
          setIdTarget(collectionObj["ID"]);
        } else if (media.includes("Companion") && collectionObj[media] !== "") {
          setBeanzId(collectionObj[media]);
          setCompanionImageUrl(collectionObj["ImageCompanion"]);
          setCompanion(true);
        } else if (media.includes("Selfie")) {
          setSelfie(collectionObj[media]);
        }
      });

      localData.add(collection, collectionObj);
    }
  }, [collection]);

  return (
    <div className="w-full bg-transparent shrink">
      <div
        className={`grid ${
          isMobileView ? "" : "grid-cols-2"
        } gap-0 min-[1300px]:gap-[150px] pt-0`}
      >
        <div
          className={`flex flex-row ${
            isMobileView ? "justify-center" : "justify-end me-5"
          } items-center gap-1 md:gap-3 `}
        >
          <span
            className={`font-primary font-semibold text-kobo-11 min-[350px]:text-kobo-13 min-[400px]:text-kobo-15 min-[440px]:text-kobo-17 sm:text-lg py-1 px-3 rounded-t-xl ${
              tab === "azuki"
                ? "bg-white text-zinc-950 cursor-default"
                : "bg-zinc-50 text-zinc-400 cursor-pointer"
            } ${orderOpen ? "blur-[2px] pointer-events-none select-none" : ""}`}
            onClick={handleAzuki}
          >
            AZUKI
          </span>
          <span
            className={`font-primary font-semibold text-kobo-11 min-[350px]:text-kobo-13 min-[400px]:text-kobo-15 min-[440px]:text-kobo-17 sm:text-lg py-1 px-3 rounded-t-xl ${
              tab === "elementals"
                ? "bg-white text-zinc-950 cursor-default"
                : "bg-zinc-50 text-zinc-400 cursor-pointer"
            } ${orderOpen ? "blur-[2px] pointer-events-none select-none" : ""}`}
            onClick={handleElementals}
          >
            ELEMENTALS
          </span>
          <span
            className={`font-primary font-semibold text-kobo-11 min-[350px]:text-kobo-13 min-[400px]:text-kobo-15 min-[440px]:text-kobo-17 sm:text-lg py-1 px-3 rounded-t-xl ${
              tab === "beanz"
                ? "text-zinc-950 bg-white cursor-default"
                : "bg-zinc-50 text-zinc-400 cursor-pointer"
            } ${orderOpen ? "blur-[2px] pointer-events-none select-none" : ""}`}
            onClick={handleBeanz}
          >
            BEANZ
          </span>
          {isMobileView && (
            <span
              className={`px-3 min-[350px]:ml-4 font-primary font-semibold text-kobo-11 min-[350px]:text-kobo-13 min-[400px]:text-kobo-15 min-[440px]:text-kobo-17 sm:text-lg py-1 rounded-t-xl 
            ${
              tab === "stickers"
                ? "text-zinc-950 bg-white cursor-default"
                : "bg-zinc-50 text-zinc-400 cursor-pointer"
            } 
            ${orderOpen ? "blur-[2px] pointer-events-none select-none" : ""}`}
              onClick={() => handleTabOpened("stickers")}
            >
              STICKERS
            </span>
          )}
        </div>
        {!isMobileView && (
          <div className="trigger-lorem flex flex-row justify-start items-center gap-3 ms-5">
            <div
              className={`flex flex-row justify-center gap-2 items-center font-primary font-semibold text-lg cursor-pointer py-1 px-3 bg-white text-zinc-950 rounded-t-xl ${
                orderOpen ? "blur-[2px] pointer-events-none select-none" : ""
              } `}
            >
              <span>STICKERS</span>
              <img
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                data-hover="info"
                src={iIcon}
                alt="info icon"
                className="w-5 h-5"
              />
              {activeStickers && !!activeStickers.length && (
                <button className="w-5 h-5" onClick={clearStickerData}>
                  <img
                    src={clearIcon}
                    alt="clear icon"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    data-hover="clear"
                  />
                </button>
              )}
            </div>
            <span
              className={`font-primary leading-[15px] font-light text-[12px] text-zinc-950 ${
                isHovering === "info" ? "" : "hidden"
              }`}
            >
              Click on a sticker to select/deselect, drag and rotate.
              <br />
              Brave browser users: deactivate shields for optimal functionality.
            </span>
            <span
              className={`font-primary leading-[15px] font-light text-[12px] text-zinc-950 ${
                isHovering === "clear" ? "" : "hidden"
              }`}
            >
              Clear Stickers
            </span>
            {hoveredStickerName !== "" && (
              <span className={`font-primary font-light text-zinc-950 text-md`}>
                {hoveredStickerName}
              </span>
            )}
          </div>
        )}
      </div>
      <div
        className={`grid ${
          isMobileView ? "min-h-[170px]" : "grid-cols-2"
        } gap-0 min-[1300px]:gap-[150px] bg-white ${
          orderOpen ? "blur-[2px] pointer-events-none select-none" : ""
        }`}
      >
        <div
          className={`flex flex-col h-full me-[30px] py-3 ${
            isMobileView && tab === "stickers" ? "hidden" : ""
          }`}
        >
          <div
            className={`flex flex-row ${
              isMobileView
                ? "justify-start pl-4 min-[420px]:pl-3"
                : "justify-end"
            } items-center`}
          >
            {collection && (
              <div
                className={`flex flex-col items-center ${
                  isMobileView
                    ? "min-[500px]:px-10 min-[600px]:px-20 min-[750px]:px-41 w-full"
                    : ""
                } `}
              >
                <div className="flex flex-row justify-start items-center w-full mt-2 gap-2">
                  <img
                    src={iconSearch}
                    className="w-5 h-5 sm:mt-2"
                    alt="small search icon"
                  />
                  <input
                    id="input-id"
                    className="font-primary font-light text-sm sm:text-md bg-transparent focus:outline-none border-b-[1px] border-zinc-950 w-[110px] rounded-none"
                    placeholder={
                      collection === "azuki"
                        ? "Azuki ID"
                        : collection === "elementals"
                        ? "Elementals ID"
                        : collection === "beanzId"
                        ? "Beanz ID"
                        : "Beanz ID"
                    }
                    type="text"
                    onFocus={thanksIOS}
                    onChange={handleIdChange}
                    value={idTarget}
                  ></input>
                </div>
                <div
                  className={`flex flex-row items-baseline w-full mt-2 ${
                    isMobileView ? "gap-5" : "justify-between gap-3 md:w-80"
                  } `}
                >
                  {collection === "azuki" &&
                    ((idTarget && idTarget !== "40" && imageUrl) ||
                      isMobileView) && (
                      <div className="flex flex-row justify-center gap-2 items-center mt-3">
                        <label
                          htmlFor="companion"
                          className="flex items-center cursor-pointer"
                        >
                          <input
                            id="companion"
                            className="form-checkbox h-4 w-4 text-gray-600 text-xs bg-transparent"
                            placeholder="companion"
                            type="checkbox"
                            onChange={handleCompanion}
                            checked={companion}
                          ></input>
                          <span className="font-primary font-light text-md ml-2">
                            Add Beanz
                          </span>
                        </label>
                      </div>
                    )}
                  {/* {error && 
                            <div className='text-red-600 bold italic text-sm'>Sorry, NFT not found</div>
                        } */}
                  {collection === "elementals" &&
                    ((idTarget && imageUrl && !error) || isMobileView) && (
                      <div className="flex flex-row justify-center gap-2 items-center mt-3">
                        <label
                          htmlFor="companion"
                          className="flex items-center cursor-pointer"
                        >
                          <input
                            id="companion"
                            className="form-checkbox h-4 w-4 text-gray-600 text-xs bg-transparent"
                            placeholder="companion"
                            type="checkbox"
                            onChange={handleCompanion}
                            checked={companion}
                          ></input>
                          <span className="font-primary font-light text-md ml-2">
                            Add Beanz
                          </span>
                        </label>
                      </div>
                    )}
                  {collection === "beanz" &&
                    ((idTarget && imageUrl) || isMobileView) && (
                      <div className="flex flex-row justify-center gap-2 items-center mt-3">
                        <label
                          htmlFor="selfie"
                          className="flex items-center cursor-pointer"
                        >
                          <input
                            id="selfie"
                            className="form-checkbox h-4 w-4 text-gray-600 text-xs bg-transparent"
                            placeholder="selfie"
                            checked={selfie}
                            type="checkbox"
                            onChange={handleSelfie}
                          ></input>
                          <span className="font-primary font-light text-md ml-2">
                            Selfie
                          </span>
                        </label>
                      </div>
                    )}
                  {companion && idTarget && !error && (
                    <div
                      className={`flex flex-row ${
                        isMobileView ? "pl-4" : ""
                      } justify-start items-center gap-2`}
                    >
                      <img
                        src={iconSearch}
                        className="w-5 h-5 mt-2"
                        alt="search icon"
                      />
                      <input
                        className="font-primary font-light text-sm md:text-md bg-transparent focus:outline-none border-b-[1px] border-zinc-950 w-[110px] mt-2 rounded-none"
                        placeholder="Beanz ID"
                        type="text"
                        onChange={handleBeanzId}
                        value={beanzId}
                      ></input>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={`flex flex-col h-full bg-white pt-3 pb-1 ${
            isMobileView && tab !== "stickers" ? "hidden" : ""
          } ${!isMobileView ? "ms-5" : ""}`}
        >
          <div
            className={`flex flex-row ${
              isMobileView ? "justify-center" : "justify-start"
            } flex-wrap items-center`}
          >
            <div className="grid grid-cols-11 space-y-0 items-center md:w-[450px]">
              {stickers.map((stickerSrc, index) => (
                <img
                  alt={"sticker" + index}
                  key={index}
                  onMouseEnter={() => handleMouseEnterSticker(stickerSrc)}
                  onMouseLeave={handleMouseLeaveSticker}
                  onClick={() => handleStickerClick(stickerSrc)}
                  src={stickerSrc}
                  className={`rounder-lg w-[2.1rem] h-8 min-[350px]:w-10 cursor-pointer object-contain ${
                    activeStickers.some((sticker) => sticker.src === stickerSrc)
                      ? ""
                      : "opaque-sticker"
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
        {tab === "stickers" && (
          <>
            <div
              className={`${
                isMobileView ? "" : "hidden"
              }  text-center pb-2 px-1 md:px-41 ${
                tab === "stickers" ? "pt-1" : ""
              }`}
            >
              <p className="text-kobo-11">
                Click on a sticker to select/deselect, drag and rotate.
              </p>

              <button
                className="inline-flex items-center"
                onClick={clearStickerData}
              >
                <img
                  className="w-5 h-5"
                  src={clearIcon}
                  alt="clear icon"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  data-hover="clear"
                />
                <span className="text-kobo-11 ml-1">Clear stickers</span>
              </button>
            </div>
          </>
        )}
        {tab !== "stickers" && (
          <p
            className={`${
              isMobileView ? "" : "hidden"
            } text-kobo-10 text-center px-2 min-[500px]:px-10 min-[600px]:px-20 md:px-41 ${
              tab === "stickers" ? "pt-1" : ""
            }`}
          >
            Create your custom deck, download the image, and{" "}
            <a
              className="underline underline-offset-2"
              href="https://twitter.com/messages/compose?recipient_id=1711767105664581632"
              target="_blank"
              rel="noreferrer"
            >
              DM us
            </a>{" "}
            to complete the order, or switch to desktop to access the complete
            workshop
          </p>
        )}
      </div>
    </div>
  );
};

export default FunctionalTabs;
