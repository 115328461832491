const azukiTraits = [
  "4198",
  "4531",
  "7047",
  "3653",
  "5532",
  "2862",
  "3148",
  "279",
  "9600",
  "5080",
  "1153",
  "4491",
  "9768",
  "7869",
  "4714",
  "7221",
  "2703",
  "9467",
  "5609",
  "2530",
  "2461",
  "3936",
  "6649",
  "8617",
  "7451",
  "1792",
  "4332",
  "7182",
  "7416",
  "2808",
  "5819",
  "1463",
  "4309",
  "3439",
  "8194",
  "4338",
  "1323",
  "9296",
  "8226",
  "6367",
  "1790",
  "2902",
  "2554",
  "6918",
  "9937",
  "9486",
  "9801",
  "3226",
  "3055",
  "3328",
  "4017",
  "2179",
  "2171",
  "191",
  "3302",
  "6993",
  "3005",
  "9509",
  "1500",
  "3352",
  "6062",
  "8760",
  "2030",
  "8161",
  "9174",
  "7675",
  "2155",
  "5014",
  "9996",
  "544",
  "8758",
  "851",
  "2882",
  "2111",
  "4286",
  "7290",
  "1059",
  "7989",
  "5424",
  "7866",
  "2404",
  "7190",
  "8384",
  "7089",
  "4944",
  "5716",
  "6709",
  "1370",
  "3850",
  "1479",
  "9990",
  "9802",
  "9185",
  "8524",
  "3240",
  "47",
  "6384",
  "4913",
  "2309",
  "9229",
  "2058",
  "7148",
  "1420",
  "7206",
  "4077",
  "1907",
];

const elementalTraits = [
  "12983",
  "6963",
  "2735",
  "8028",
  "2057",
  "8001",
  "1333",
  "15885",
  "7265",
  "18327",
  "16533",
  "7542",
  "16042",
  "3509",
  "15180",
  "16702",
  "5164",
  "14417",
  "2897",
  "3726",
  "12162",
  "4094",
  "17699",
  "6020",
  "18142",
  "10633",
  "4589",
  "3889",
  "13097",
  "10302",
  "10909",
  "15624",
  "15437",
  "7326",
  "935",
  "8846",
  "2025",
  "13121",
  "13532",
  "11207",
  "9573",
  "8760",
  "3133",
  "18232",
  "8628",
  "3011",
  "574",
  "4914",
  "15956",
  "11468",
  "15012",
  "6264",
  "12675",
  "15240",
  "11774",
  "772",
  "17838",
  "10846",
  "387",
  "19737",
  "10704",
  "4742",
  "6377",
  "14279",
  "3464",
  "1189",
  "3733",
  "18867",
  "369",
  "18603",
  "14252",
  "6939",
  "13347",
  "1980",
  "18617",
  "2975",
  "18605",
  "5773",
  "14293",
  "18006",
  "6034",
  "13424",
  "5682",
  "2592",
  "10308",
  "12471",
  "14083",
  "6519",
  "3903",
  "628",
  "374",
  "12674",
  "3026",
  "5167",
  "1097",
  "3814",
  "6244",
  "9538",
  "12250",
  "19191",
  "14822",
  "19890",
  "18788",
  "9705",
  "13952",
  "13459",
  "6088",
  "8348",
  "19468",
  "5193",
  "535",
  "11723",
  "2178",
  "1363",
  "11818",
  "13888",
  "19390",
  "19897",
  "7419",
  "6316",
  "8612",
  "19791",
  "2081",
  "8112",
  "1224",
  "15484",
  "2701",
  "18621",
  "7284",
  "7217",
  "19501",
  "1392",
  "12638",
  "12982",
  "7654",
  "6030",
  "3325",
  "14513",
  "18146",
  "4057",
  "14523",
  "13566",
  "405",
  "4345",
  "4192",
  "1394",
  "18252",
  "1594",
  "6656",
  "17206",
  "11767",
  "14855",
  "17381",
  "16452",
  "13230",
  "6514",
  "17157",
  "10725",
  "12606",
  "9210",
  "15818",
  "3966",
  "4080",
  "9084",
  "13152",
  "1426",
  "2133",
  "577",
  "12070",
  "12263",
  "18244",
  "7902",
  "19693",
  "10358",
  "17894",
  "19354",
  "16240",
  "4226",
  "3799",
  "7364",
  "17241",
  "6550",
  "16678",
  "4729",
  "18307",
  "4573",
  "6137",
  "18438",
  "4700",
  "18416",
  "15752",
  "11823",
  "15148",
  "7158",
  "16540",
  "6718",
  "18335",
  "17589",
  "4106",
  "18678",
  "7537",
  "12571",
  "8188",
  "4745",
  "13245",
  "17842",
  "14580",
  "3920",
  "11071",
  "13663",
  "14182",
  "396",
  "8909",
  "10931",
  "10650",
  "13364",
  "19755",
  "12155",
  "14888",
  "106",
  "10150",
  "687",
  "10310",
  "6925",
  "17736",
  "3680",
  "7297",
  "8739",
  "6260",
  "1711",
  "9760",
  "6376",
  "177",
  "8304",
  "1355",
  "7306",
  "13553",
  "19796",
  "4287",
];

const beanzTraits = [
  "2467",
  "16526",
  "8931",
  "5555",
  "5189",
  "19526",
  "14946",
  "18096",
  "13846",
  "6315",
  "4102",
  "10362",
  "11307",
  "19610",
  "8074",
  "5398",
  "5425",
  "485",
  "842",
  "10848",
  "14449",
  "8404",
  "3865",
  "16904",
  "14588",
  "19887",
  "11295",
  "3719",
  "5824",
  "11429",
  "2496",
  "8817",
  "16820",
  "9938",
  "12138",
  "14851",
  "2871",
  "8631",
  "12031",
  "7192",
  "15469",
  "1632",
  "9576",
  "14439",
  "2944",
  "17387",
  "11865",
  "2644",
  "14354",
  "19623",
  "3633",
  "11533",
  "1604",
  "1796",
  "18445",
  "6733",
  "2960",
  "13529",
  "1780",
  "12535",
  "10706",
  "18396",
  "4064",
  "7188",
  "11959",
  "1329",
  "18307",
  "8617",
  "17341",
  "3516",
  "16021",
  "12270",
  "1701",
  "18657",
  "6161",
  "18938",
  "2657",
  "5225",
  "18093",
  "15153",
  "2520",
  "1639",
  "15529",
  "810",
  "5552",
  "1795",
  "6021",
  "5444",
  "2947",
  "10494",
  "19688",
  "17584",
  "4094",
  "9447",
  "19634",
  "4411",
  "4482",
  "1958",
  "15400",
  "11975",
  "8729",
  "12399",
  "1887",
  "5483",
  "18511",
  "7520",
  "12489",
  "14712",
  "14587",
  "14064",
  "6483",
  "6932",
  "14736",
  "19742",
  "17428",
  "9225",
  "11672",
  "18888",
  "133",
  "6413",
  "9686",
  "7253",
  "13823",
  "14318",
  "1858",
  "11577",
  "8456",
  "15382",
  "10137",
  "17327",
  "4704",
  "17577",
  "9577",
  "9109",
  "6752",
  "8775",
  "9868",
  "9461",
  "13177",
  "14074",
  "17954",
  "5285",
  "19893",
  "13029",
  "5449",
  "19024",
  "17162",
  "16208",
  "19012",
  "7916",
  "18047",
  "1213",
  "15962",
  "5751",
  "2915",
  "17521",
  "10793",
  "12896",
  "10879",
  "11305",
  "1046",
  "1862",
  "8162",
  "11715",
  "12979",
  "9455",
  "4825",
  "10200",
  "11843",
  "2083",
  "19304",
  "8967",
  "9282",
  "2234",
  "5104",
  "909",
  "6394",
  "4489",
  "19493",
  "979",
  "10301",
  "286",
  "5416",
  "8274",
  "17205",
  "9993",
  "15827",
  "16669",
  "18864",
  "2749",
  "7945",
  "2416",
  "19506",
  "7800",
  "13899",
  "650",
  "6053",
  "1592",
  "10291",
  "9673",
  "3841",
  "13604",
  "1320",
  "9834",
  "10473",
  "18397",
  "9264",
  "7321",
  "14625",
  "7554",
  "15668",
  "3395",
  "13001",
  "9817",
  "13035",
  "1291",
  "6063",
  "5509",
  "2335",
  "5791",
  "18482",
  "10151",
  "14569",
  "14324",
  "13803",
  "9507",
  "12671",
  "2221",
  "8157",
  "7419",
  "1094",
  "10095",
  "19197",
  "4783",
  "10457",
  "16578",
  "18608",
  "1208",
  "8092",
  "4115",
  "1414",
  "74",
  "8868",
  "5635",
  "12855",
  "839",
  "3212",
  "19014",
  "7599",
  "7773",
  "17873",
  "144",
  "9211",
  "17468",
  "8519",
  "16001",
  "13553",
  "19339",
  "360",
  "12104",
  "18237",
  "2610",
  "3672",
  "10222",
  "4101",
  "500",
  "3999",
  "10819",
  "526",
  "1434",
  "1980",
  "18125",
  "5012",
  "14345",
  "9367",
  "5917",
  "12168",
  "2047",
  "19936",
  "10683",
  "14729",
  "3099",
  "7008",
  "19906",
  "8673",
  "1169",
  "1657",
  "14886",
  "3643",
  "7000",
  "11187",
  "1712",
  "5121",
  "2411",
  "14802",
  "8517",
  "12483",
  "11990",
  "11022",
  "9535",
];

export function checkTrait(collection, id, companionId) {
  let getDiscount = false;
  if (collection === "azuki") {
    getDiscount = azukiTraits.includes(id);
  } else if (collection === "elementals") {
    getDiscount = elementalTraits.includes(id);
  } else if (collection === "beanz") {
    getDiscount = beanzTraits.includes(id);
  }

  if (companionId) {
    getDiscount = getDiscount || beanzTraits.includes(companionId);
  }

  return getDiscount;
}
