import React, {useState, useEffect} from 'react';
import SkateboardMockup from './SkateboardMockup';
const elementalsData = require('../data/elementals')
const servercall = `${process.env.REACT_APP_SERVER_BASE_URL}`

const Search = () => {

    const [collection, setCollection] = useState('')
    console.log(collection)
    const [companion, setCompanion] = useState(false)
    const [selfie, setSelfie] = useState(false)
    const [beanzId, setBeanzId] = useState('');
    //const [imageUrl, setImageUrl] = useState('');
    const [mainImageUrl, setMainImageUrl] = useState('');
    const [companionImageUrl, setCompanionImageUrl] = useState('');
    const [idTarget, setIdTarget] = useState('');

    let azukiLink = 'https://azk.imgix.net/big_azukis/a-';
    let azukiCompanionLink = 'https://azukiimagemaker.vercel.app/api/pairbeanz-prod?azukiId=';
    let azukiCompanionLinkExt = '&beanzId=';

    let elementalsLink = 'https://elementals-images.azuki.com/';
    let elementalsCompanionLink = 'https://azukiimagemaker.vercel.app/api/pairbeanz-elemental?elementalId=';
    let elementalsCompanionLinkExt = '&beanzId=';

    let beanzLink = 'https://azkimg.imgix.net/images/final-';
    let selfieLink = 'https://azkimg.imgix.net/images_squareface/final-'


    useEffect(() => {
        if (collection === 'beanz' && selfie) {
            const newImageUrl = `${selfieLink}${idTarget}.png`;
            //setImageUrl(newImageUrl);
            setMainImageUrl(newImageUrl)
        }
    }, [selfie, collection, idTarget]);

    const handleAzuki = () =>{
        clear();
        setCollection('azuki')
    };

    const handleElementals = () => {
        clear();
        setCollection('elementals')
    };

    const handleBeanz = () => {
        clear();
        setCollection('beanz')
    }

    const handleIdChange = (event) => {
        setIdTarget(event.target.value);
    };

    const clear = () => {
        setCollection('');
        setCompanion(false);
        setSelfie(false);
        setBeanzId('');
        setIdTarget('');
        //setImageUrl('');
        setMainImageUrl('')
    }


    const handleCompanion = (event) => {
        setCompanion(event.target.checked);
    };

    const handleBeanzId = (event) => {
        setBeanzId(event.target.value);
    };

    const handleSelfie = (event) => {
        setSelfie(event.target.checked);
        console.log(selfie)
    };

    /*const findImage = () => {
        let newImageUrl = '';
        if (collection === 'azuki') {
            if (beanzId) {
                newImageUrl = `${azukiCompanionLink}${idTarget}${azukiCompanionLinkExt}${beanzId}`;
                console.log(newImageUrl)
            } else {
                newImageUrl = `${azukiLink}${idTarget}.png`;
                console.log(newImageUrl)
            }
        } else if (collection === 'elementals') {
            const elementalId = idTarget;
            const elementalObject = elementalsData.find((element) => element[elementalId]);
            if (elementalObject) {
                const elementalUUID = elementalObject[elementalId];
                console.log(elementalUUID)
                newImageUrl = beanzId ? 
                    `${elementalsCompanionLink}${idTarget}${elementalsCompanionLinkExt}${beanzId}` :
                    `${elementalsLink}${elementalUUID}-bigazuki.png`;
                    console.log(newImageUrl)
            }
        } else if (collection === 'beanz') {
            if (selfie) {
                newImageUrl = `${selfieLink}${idTarget}.png`;
                console.log('selfie', selfie)
            } else {
                newImageUrl = `${beanzLink}${idTarget}.png`;
            }
        }
        else if (collection === 'beanz' && !selfie) {
            newImageUrl = `${beanzLink}${idTarget}.png`;
            console.log(newImageUrl)
        }
        
            const proxyUrl = `http://localhost:5050/?imageUrl=${encodeURIComponent(newImageUrl)}`;
            setImageUrl(proxyUrl);
    };*/

    const findImage = () => {
        let url = '';
        let companionUrl = '';
        if (collection === 'azuki') {
            url = `${azukiLink}${idTarget}.png`;
            if (companion && beanzId) {
                companionUrl = `${azukiCompanionLink}${idTarget}${azukiCompanionLinkExt}${beanzId}`;
            }
        } else if (collection === 'elementals') {
            const elementalObject = elementalsData.find((element) => element[idTarget]);
            if (elementalObject) {
                url = `${elementalsLink}${elementalObject[idTarget]}-bigazuki.png`;
                if (companion && beanzId) {
                    companionUrl = `${elementalsCompanionLink}${idTarget}${elementalsCompanionLinkExt}${beanzId}`;
                }
            }
        } else if (collection === 'beanz') {
            url = `${beanzLink}${idTarget}.png`;
            if (selfie) {
                companionUrl = `${selfieLink}${idTarget}.png`;
            }
        }

        // Aggiorna l'URL principale
        const proxyUrl = `${servercall}?imageUrl=${encodeURIComponent(url)}`;
        setMainImageUrl(proxyUrl);

        // Aggiorna l'URL del companion se esiste
        if (companionUrl) {
            const proxyCompanionUrl = `${servercall}?imageUrl=${encodeURIComponent(companionUrl)}`;
            setCompanionImageUrl(proxyCompanionUrl);
        } else {
            setCompanionImageUrl(''); // Assicurati di pulire l'immagine companion se non è selezionata
        }
    };
    



  return (
    <>
        <div class='flex flex-col lg:flex lg:flex-row lg:justify-center lg:items-center gap-5 overflow-y-hidden'>
            <div class='flex flex-col justify-center items-center w-[500px]'>
                <div class='flex flex-row gap-1 justify-center g-white rounded-lg font-bold'>
                    <button   className={`${collection === 'azuki' ? 'bg-orange-300 p-2 rounded-lg' : null}`} onClick={handleAzuki}> AZUKI</button>
                    <button className={`${collection === 'elementals' ? 'bg-orange-300 p-2 rounded-lg' : null}`} onClick={handleElementals}> ELEMENTALS</button>
                    <button className={`${collection === 'beanz' ? 'bg-orange-300 p-2 rounded-lg' : null}`} onClick={handleBeanz}> BEANZ</button>
                </div>
                <div class='flex flex-row justify-center gap-2 mt-2 '>
                    <input class='w-60 font-primary border-2 border-orange-300 bg-orange-300 rounded-lg p-2' placeholder='id' type='text' onChange={handleIdChange} value={idTarget}></input>
                    <button onClick={findImage} className='bg-orange-300 p-2 rounded-lg'>FIND</button>
                </div>
                    {collection === 'azuki' && idTarget && mainImageUrl &&
                        (
                        <>
                            <div class='flex justify-center items-center gap-2 g-white p-5 rounded-lg '>
                                <input class='w-10 h-10 shadow-lg rounded-lg bg-transparent' placeholder='companion' type='checkbox' onChange={handleCompanion}></input>
                                <span>COMPANION</span>
                                {/*<label for="myCheckbox">COMPANION</label>*/}
                            </div>
                        </>
                        )
                    }
                    {collection === 'elementals' && idTarget && mainImageUrl &&
                        (
                        <>
                            <div class='flex justify-center items-center gap-2 g-white p-5 rounded-lg'>
                                <input class='w-10 h-10' placeholder='companion' type='checkbox' onChange={handleCompanion}></input>
                                <span>COMPANION</span>
                            </div>
                        </>
                        )
                    }
                    {collection === 'beanz' && idTarget && mainImageUrl &&
                        (
                        <>
                            <div class='flex justify-center gap-2 g-white  p-5 rounded-lg'>
                                <input class='w-10 h-10 rounded-lg bg-orange-300' placeholder='selfie' checked={selfie} type='checkbox' onChange={handleSelfie}></input>
                                <span>SELFIE</span>
                            </div>
                        </>
                        )
                    }
                    {companion &&
                    (
                    <>
                        <div class='flex flex-row justify-center gap-2 mt-2'>
                            <input class='w-60 rounded-lg bg-orange-300 p-2 text-black' placeholder='BeanzId' type='text' onChange={handleBeanzId}></input>
                            <button onClick={findImage} className='bg-orange-300 p-2 rounded-lg'>FIND</button>
                        </div>
                    </>
                    )
                    }
            </div>
            { mainImageUrl && (
            <div className="flex flex-row justify-center items-center p-5 w-[500px] h-[500px]">
                <SkateboardMockup imageUrl={mainImageUrl} companionImageUrl={companionImageUrl} collection={collection} selfie={selfie} />                
            </div>
            )
            }
        </div>
    </>
  )
}

export default Search